import {http} from './api';
import {createCancelTokenHandler} from "./axiosUtils";

export const ChainsService = {
  create: (data, id, tariff, locale) => http.get(`chains/${id}`, data, {
    params: {
      locale: locale,
      tariff: tariff,
    },
    cancelToken: cancelTokenHandler['create'].handleRequestCancellation().token
  }),
};

const cancelTokenHandler = createCancelTokenHandler(ChainsService)


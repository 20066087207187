import * as React from 'react';

const AnalyseForm = React.lazy(() => import('../forms/AnalyseForm'));
const TabMenu = React.lazy(() => import('../components/TabMenu'));
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {LocaleProvider} from '../contexts/LocaleContext';
import Spinner from '../components/Spinner';
import { i18n } from "../config/i18n";
import {ToastContainer} from 'react-toastify';
import {UserProvider} from "../contexts/UserContext";
import {IUser, IYearCatalogs} from "../interfaces";
import {IDrugsData} from "../components/patient-case/Drug";
import {getUrlParam} from "../utils/UrlUtils";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";

interface Props {
    id: number,
    locale: string,
    pageName: string,
    caseNumber?: string,
    links?: {[Identifier: string] : string},
    drugsData?: IDrugsData,
    user?: IUser,
    hideMenu?: boolean,
    catalogs: IYearCatalogs
}

const Analyse: React.FunctionComponent<Props> = ({
                                                     id,
                                                     pageName,
                                                     caseNumber,
                                                     links,
                                                     user,
                                                     locale,
                                                     hideMenu,
                                                     catalogs
                                                 }) => {
    return (
        <>
            <Router>
                <Route
                    path='/'
                    render={({location, history}) => {
                        const urlEncodedPc = getUrlParam('pc', location.search);
                        //const codingState = getUrlParam('coding_state', location.search);
                        i18n.locale = locale;

                        return (
                            <LocaleProvider value={locale}>
                                <YearCatalogsProvider value={catalogs}>
                                    <UserProvider value={user}>
                                        <>
                                            {!hideMenu &&
                                                <React.Suspense fallback={<Spinner/>}>
                                                    <TabMenu
                                                        urlEncodedPc={urlEncodedPc}
                                                        active={pageName}
                                                        caseNumber={caseNumber}
                                                        id={id}
                                                        links={links}
                                                    />
                                                </React.Suspense>}
                                            {pageName === 'basic' &&
                                                <React.Suspense fallback={<Spinner/>}>
                                                    <AnalyseForm
                                                        urlEncodedPc={urlEncodedPc}
                                                        history={history}
                                                        id={id}
                                                    />
                                                </React.Suspense>}
                                            </>
                                        </UserProvider>
                                    </YearCatalogsProvider>
                            </LocaleProvider>
                        )
                    }}/>
            </Router>
            <ToastContainer hideProgressBar={true} icon={false}/>
        </>
    )
}

export default Analyse

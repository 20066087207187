import * as React from 'react';
import { i18n } from "../../config/i18n";
import {ISummary, IYearCatalogs} from "../../interfaces";
import CodesTable from "./CodesTable";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useContext} from "react";
import {YearCatalogsContext} from "../../contexts/YearCatalogsContext";
import {IInteractiveSummaryProps} from "../summary-of-cases/Summary";

interface Props {
    summary: ISummary,
    summary2: ISummary,
    isAdmin: boolean,
    codeInfos: {[key: string] : [string, [string[], string][]][]},
    interactiveProps?: IInteractiveSummaryProps,
}

const CodesTables: React.FunctionComponent<Props> = props => {
    const {summary, summary2, isAdmin, codeInfos, interactiveProps} = props;
    const isDynamic = interactiveProps != undefined
    const catalogs = useContext<IYearCatalogs>(YearCatalogsContext)
    // filter tarpos by drug or rest.
    // Todo: Change to more generic code with next group / tab.
    const drugTariffs = ['402', '399']
    const drugTarpos = summary.commonTarpos.filter(t => drugTariffs.includes(t[0][1]))
    const drugTarpos2 = summary2 ? summary2.commonTarpos.filter(t => drugTariffs.includes(t[0][1])) : undefined
    const otherTarpos = summary.commonTarpos.filter(t => !drugTariffs.includes(t[0][1]))
    const otherTarpos2 = summary2 ? summary2.commonTarpos.filter(t => !drugTariffs.includes(t[0][1])) : undefined

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <strong>{i18n.t('common_codes')}</strong>
                        </div>
                        <div className="card-body p-3 tab-content" id="dataTableCollapse">
                            <Tabs
                                defaultActiveKey="tarpos"
                                id="codes-lists"
                                className="mb-3"
                            >
                                <Tab eventKey="tarpos" title={i18n.t('tarpos') + ' ' + catalogs.year}>
                                    <CodesTable
                                        title={'tarpos'}
                                        setA={{
                                            data: otherTarpos,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: otherTarpos2,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        hasTariff={true}
                                        codeInfos={codeInfos['tarpos']}
                                        numCasesTitle={'num_codings'}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>
                                <Tab eventKey="service_catalog" title={i18n.t('services_lkaat') + ' LKAAT ' + catalogs.serviceCatalog}>
                                    <CodesTable
                                        title={'services_lkaat'}
                                        setA={{
                                            data: summary.commonServices,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: summary2.commonServices,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        codeInfos={codeInfos['services']}
                                        numCasesTitle={'num_codings'}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>
                                <Tab eventKey="drugs" title={i18n.t('drugs') + ' GTIN'}>
                                    <CodesTable
                                        title={'drugs'}
                                        setA={{
                                            data: drugTarpos,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: drugTarpos2,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        hasTariff={true}
                                        codeInfos={codeInfos['tarpos']}
                                        numCasesTitle={'num_codings'}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>
                                {(summary.commonPdx.length > 0 || (summary2 ? summary2.commonPdx.length > 0 : false)) &&
                                    <Tab eventKey="pdxs" title={i18n.t('diagnoses') + ' ' + catalogs.icd}>
                                        <CodesTable
                                            title={'diagnoses'}
                                            setA={{
                                                data: summary.commonPdx,
                                                numberOfPatientcases: summary.numberOfPatientcases
                                            }}
                                            setB={summary2 ? {
                                                data: summary2.commonPdx,
                                                numberOfPatientcases: summary2.numberOfPatientcases
                                            } : null}
                                            summaryName1={summary.name}
                                            summaryName2={summary2 ? summary2.name : ''}
                                            codeInfos={codeInfos['pdx']}
                                            numCasesTitle={'num_codings'}
                                            interactiveProps={interactiveProps}
                                        />
                                    </Tab>}
                                {(summary.commonDrg.length > 0 || (summary2 ? summary2.commonDrg.length > 0 : false)) &&
                                    <Tab eventKey="amb_groups" title={i18n.t('amb_groups') + ' ' + catalogs.ambGroup}>
                                        <CodesTable
                                            title={'amb_groups'}
                                            setA={{
                                                data: summary.commonDrg,
                                                numberOfPatientcases: summary.numberOfPatientcases
                                            }}
                                            setB={summary2 ? {
                                                data: summary2.commonDrg,
                                                numberOfPatientcases: summary2.numberOfPatientcases
                                            } : null}
                                            summaryName1={summary.name}
                                            summaryName2={summary2? summary2.name : ''}
                                            codeInfos={codeInfos['drgs']}
                                            interactiveProps={interactiveProps}
                                        />
                                    </Tab>}
                                {((summary.commonHospitals.length > 0 || (summary2 ?
                                            summary2.commonHospitals.length > 0 : false     ))
                                        // Only show hospitals if admin or summary is computed on the go,
                                        // i.e. no data breach.
                                        && (isAdmin || isDynamic)) &&
                                    <Tab eventKey="hospitals" title={i18n.t('hospitals')}>
                                        <CodesTable
                                            title={'hospitals'}
                                            setA={{
                                                data: summary.commonHospitals,
                                                numberOfPatientcases: summary.numberOfPatientcases
                                            }}
                                            setB={summary2 ? {
                                                data: summary2.commonHospitals,
                                                numberOfPatientcases: summary2.numberOfPatientcases
                                            } : null}
                                            summaryName1={summary.name}
                                            summaryName2={summary2? summary2.name : ''}
                                            codeInfos={codeInfos['hospitals']}
                                            interactiveProps={interactiveProps}
                                        />
                                    </Tab>}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CodesTables

import * as React from 'react';
import Autocomplete from './Autocomplete';
import {TarposService} from '../services/TarposService.js';
import {Field} from "formik";


interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    tariff: string;
    submitForm: Function;
}

const TarpoAutocomplete: React.FunctionComponent<Props> = props => {
    const {tariff, name, value} = props


    const searchUrlPrefixes = {
        '001' : 'tarmeds/$$VERSION$$',
        '317' : 'laboratory_analyses/AL',
        '452' : 'migels/MIGEL',
        '454' : 'migels/MIGEL',
        '402' : 'drugs/DRUG',
        '003' : 'tariff003_codes/1.53',
        '007' : 'tardocs/$$VERSION$$',
        'LKN' : 'service_catalogs/$$VERSION$$',
    }

    // Tariff-Catalog mapping for catalofs with versions used in catalog props (tariff003 has a version but no model).
    const tariffCatalogMapping = {
        '001' : 'tarmed',
        '007' : 'tardoc',
        'LKN' : 'serviceCatalog',
    }
    const urlPrefix = searchUrlPrefixes[tariff || '001']
    return (
        <>
        { urlPrefix == undefined ?
            <Field type="float"
                   name={name}
                   id={name}
                   className={"form-control"}
                   value={value}
            /> : <Autocomplete {...props} service={TarposService.create(urlPrefix, tariffCatalogMapping[tariff])}/>}
        </>
    );
};

export default TarpoAutocomplete;

import * as React from 'react';
import {FieldArray, FormikValues} from 'formik';
import ColorMeter from './ColorMeter';
import DiagnoseAutocomplete from "../../form-fields/DiagnoseAutocomplete";
import Diagnose from "./Diagnose";
import { i18n } from "../../config/i18n";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {YearCatalogsContext} from "../../contexts/YearCatalogsContext";
import {useContext} from "react";
import {IYearCatalogs} from "../../interfaces";

interface Props {
    setFieldValue: Function,
    values: FormikValues,
    submitForm: Function,
    changeValue: Function,
    colorMeterEnabled?: boolean,
}

const Diagnoses: React.FunctionComponent<Props> = props => {
    const {values, changeValue, setFieldValue, submitForm, colorMeterEnabled} = props;
    const mainDiagnosis = values.main_diagnosis;
    const catalogs = useContext<IYearCatalogs>(YearCatalogsContext)

    return (
        <div className="codes_box" id="ddx_box">
            <div className="row vertical-spacer">
                <div className="col-sm-12">
                    <h4>
                        {i18n.t("diagnosis")}
                        <small className="text-secondary ms-2" style={{fontSize: '0.5em'}}>
                            {catalogs.icd}
                        </small></h4>
                </div>
                <div className="col-sm-12 p-0">
                    <div className={'code_input_div row ' + (mainDiagnosis && !mainDiagnosis.active ? 'disabled' : '')}>
                        <div className="col-sm-3">
                            <div className="input-group">
                                <DiagnoseAutocomplete
                                    name={`main_diagnosis.code`}
                                    value={mainDiagnosis && mainDiagnosis.code || ""}
                                    valid={mainDiagnosis && mainDiagnosis.valid}
                                    used={mainDiagnosis && mainDiagnosis.used}
                                    setValue={setFieldValue}
                                    submitForm={submitForm}
                                />
                                {mainDiagnosis &&
                                    <span className="fa fa-check removetag me-3"
                                          title={i18n.t(`${mainDiagnosis.active ? 'de' : ''}activate_code`)}
                                          onClick={async () => {
                                              setFieldValue(`main_diagnosis.active`, !mainDiagnosis.active);
                                              await Promise.resolve();
                                              submitForm();
                                          }}/>
                                }
                                <i className="fa fa-remove removetag" title={i18n.t('delete_code')}
                                   onClick={() => {
                                       changeValue('main_diagnosis', {code: ''});
                                       submitForm();
                                   }}/>
                            </div>
                        </div>
                        {mainDiagnosis &&
                        <>
                            {mainDiagnosis.color && mainDiagnosis.valid && mainDiagnosis.code && colorMeterEnabled &&
                            <div className="col-sm-1 px-0">
                              <ColorMeter color={mainDiagnosis.color}/>
                            </div>}
                            {mainDiagnosis.text &&
                            <div className="col-sm-8 code_description" id="pdxDescription">
                              <a href={mainDiagnosis.url} target={mainDiagnosis.code}>{mainDiagnosis.text}</a>
                            </div>}
                        </>}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Diagnoses

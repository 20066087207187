import * as React from 'react';
import {Formik, Form, Field, FormikValues} from 'formik';
import { i18n } from "../config/i18n";
import InputField from "../form-fields/InputField";
import TextareaField from "../form-fields/TextareaField";
import CheckboxField from "../form-fields/Checkbox";
import SelectField from "../form-fields/SelectField";
import {useEffect, useState} from "react";
import MCLEditor from "../components/medcodelogic/MCLEditor";
import MCLEditorErrorBoundary from "../components/medcodelogic/MCLEditorErrorBoundary";

interface Props {
    values?: FormikValues,
    onSubmit: any,
    onCancel: any,
    patient_classification_system_id: string
}

const ClassificationNodeForm: React.FunctionComponent<Props> = (props) => {
    const [systems, setSystems] = useState<{name: string, id: string}[]>([])
    const [showAddNodeFrom, setShowAddNodeForm] = useState<boolean>(false)
    const [codeWildcards, setCodeWildcards] = useState<{params: string, code: string}[]>([])

    useEffect(() => {
        fetch('/classification_systems/systems')
            .then(response => response.json())
            .then(data => setSystems(data.systems.map(e => ({label: e.name, value: e.id}))));
        parseWildcards(props.values.logic);
    }, [])

    function getInitState() {
        return (
            {
                node_type: props.values && !!props.values.classname ? 'classification' : 'decision',
                rank: -1,
                pcs_id: '',
                systems: []
            }
        )
    }

    function parseWildcards(logic) {
        if(logic == null)
            logic = '';
        const listMatches = logic.match(/[a-z][_a-z\d]* in list[\s]*\([^)]*\)/gi);
        if(listMatches === null) {
            setCodeWildcards([])
            return;
        }
        const wildcards = [];
        // eslint-disable-next-line no-unused-vars
        listMatches.forEach(function (listMatch, _lindex) {
            const match = listMatch.match(/^[a-z][a-z\d]*/)
            if(match === null)
                return
            const variable = match[0]
            const codeType = {'pdx': 'icd',
                'diagnoses': 'icd',
                'secondary_diagnoses': 'icd',
                'main_diagnosis': 'icd',
                'procedures': 'chop',
                'drg': 'drg',
                'tarpos': 'tarmed',
                'services': 'service_catalog'}[variable]
            const allWildcards = []
            // eslint-disable-next-line no-unused-vars
            listMatch.match(/\([^)]*\)/)[0].replace(/[()\s]/g, '').split(',').forEach(function (codeMatch, _cindex) {
                const name = codeMatch.replace('*', '_').replace('.', '_');
                wildcards.push({params: "table_type=" + codeType + "&name=" + name + "&codes_wildcard=" + codeMatch, code: codeMatch});
                allWildcards.push(codeMatch);
            });
            if(allWildcards.length > 1){
                const allPattern = allWildcards.join(',')
                wildcards.unshift({params: "table_type=" + codeType + "&codes_wildcard=" + allPattern, code: i18n.t('all')})
            }
        });
        setCodeWildcards(wildcards)
    }

    return (
        <Formik
            initialValues={Object.assign({}, getInitState(), props.values)}
            onSubmit={(values, {setSubmitting}) => {
                props.onSubmit(values);
                setSubmitting(false);
            }}
        >
            {({values, setFieldValue}) => (
                <div>
                    <Form className="edit_pcs_node">

                        {props.values.errors && props.values.errors.length !== 0 &&

                            <div className="alert alert-danger">
                                <ul className="mb-0 pb-0">
                                    {props.values.errors.map(
                                        (e, index) => <li key={index} dangerouslySetInnerHTML={{__html: e.msg}}/>
                                    )}
                                </ul>
                            </div>
                        }

                        <div className="form-group">
                            <label>
                                <Field
                                    name="node_type"
                                    type="radio"
                                    value="decision"
                                />
                                {' '}{i18n.t('decision')}
                            </label>
                            {' '}
                            <label>
                                <Field
                                    name="node_type"
                                    type="radio"
                                    value="classification"
                                />
                                {' '}{i18n.t('classification')}
                            </label>
                        </div>

                        {values.node_type === "decision" &&
                            <div className="form-group">
                                <MCLEditorErrorBoundary editor={
                                    <MCLEditor logicSrc={values.logic}
                                               setLogicSrc={(logicSrc) => {
                                                   setFieldValue("logic", logicSrc);
                                                   parseWildcards(logicSrc)
                                               }}/>
                                }/>
                                {codeWildcards.length > 0 &&
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9 form-inline mt-1 dropdown show">
                                            <a className="btn btn-outline-secondary dropdown-toggle"
                                               id="new_dropdown"
                                               aria-expanded="false"
                                               data-bs-toggle="dropdown"
                                               role="button">
                                                {i18n.t('new_table')}</a>
                                            <div className="dropdown-menu" aria-labelledby="new_dropdown">
                                                {codeWildcards.map((codeWildcard, index) => (
                                                    <a className="dropdown-item"
                                                       key={index}
                                                       href={'/tables/prepare_new_by_regexp?patient_classification_system_id=' +
                                                           props.patient_classification_system_id + '&' + codeWildcard.params}
                                                       target="new_tables">
                                                        <i className="fa fa-plus action action-add"/>
                                                        {codeWildcard.code}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>}

                        {values.node_type === "classification" &&
                            <div className="form-group">
                                <InputField
                                    name="classname"
                                    grid={{label: 3, input: 9}}
                                />
                            </div>}

                        {values.chapter_entry &&
                            <div className="form-group">
                                <TextareaField
                                    name="chapter_code"
                                    grid={{label: 3, input: 9}}
                                />
                            </div>
                        }

                        <div className="form-group">
                            <TextareaField
                                name="description"
                                grid={{label: 3, input: 9}}
                            />
                        </div>

                        {values.node_type === "decision" &&
                            <div className="form-group">
                                <CheckboxField
                                    name="chapter_entry"
                                    grid={{label: 3, input: 9}}
                                />
                            </div>
                        }

                        <input type="submit" name="commit" value={i18n.t('save')} className="btn btn-primary"/>
                        {' '}
                        <a onClick={props.onCancel} className="btn btn-outline-secondary">{i18n.t('cancel')}</a>


                    </Form>
                    {values.chapter_entry &&
                        <div className="row mt-2">
                            <div className="col-lg-12">
                                <a
                                    data-method="post"
                                    className="btn btn-outline-secondary me-2"
                                    title={i18n.t('extract_pcs_description')}
                                    href={'/pcs_nodes/' + values.id + '/extract'}
                                >
                                    {i18n.t('extract_pcs')}
                                </a>
                                <button
                                    data-method="post"
                                    className="btn btn-outline-secondary me-2"
                                    title={i18n.t('add_pcs_description')}
                                    onClick={() => {
                                        setShowAddNodeForm(!showAddNodeFrom)
                                        setFieldValue('pcs_id', systems[0].id)
                                    }}
                                >
                                    {i18n.t('add_pcs')}
                                </button>
                            </div>
                        </div>
                    }
                    {showAddNodeFrom &&
                        <div className="row mt-2 card-footer">
                            <div className="col-lg-12">
                                <h4>{i18n.t('add_pcs')}</h4>
                                <SelectField
                                    name="pcs_id"
                                    grid={{label: 0, input: 8}}
                                    options={systems}
                                />

                                <a
                                    data-method="post"
                                    className="btn btn-outline-secondary me-2"
                                    href={'/pcs_nodes/' + values.id + '/add_pcs?&patient_classification_system_id=' + values.pcs_id}
                                >
                                    {i18n.t('save')}
                                </a>
                            </div>
                        </div>
                    }
                </div>
            )}
        </Formik>
    );
}

export default ClassificationNodeForm;

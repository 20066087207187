import * as React from 'react';
import ServiceAutocomplete from "../../form-fields/ServiceAutocomplete";
import {Field} from "formik";
import { i18n } from "../../config/i18n";
import {useContext} from "react";
import {IService, IUser} from "../../interfaces";
import {UserContext} from "../../contexts/UserContext";
import DatePicker from "../../form-fields/DatePicker";

interface Props {
    index: any;
    service: IService;
    setFieldValue: any;
    submitForm: any;
    arrayHelpers: any;
}

const Service: React.FunctionComponent<Props> = props => {
    const {index, service, setFieldValue, submitForm, arrayHelpers} = props;
    const user = useContext<IUser>(UserContext)

    return (
        <div key={index}>
            <div className="code_input_div row">
                <div className="col-lg-7 pe-0">
                    <div className={'input-group ' + (!service.active ? 'disabled' : '')}>
                        <div className="col-sm-4 px-0">
                            <ServiceAutocomplete
                                name={`services[${index}].code`}
                                value={service && service.code || ""}
                                setValue={setFieldValue}
                                valid={service.valid}
                                submitForm={submitForm}
                            />
                            <span className="fa fa-check removetag me-3"
                                  title={i18n.t(`${service.active ? 'de' : ''}activate_code`)}
                                  onClick={async () => {
                                      setFieldValue(`services[${index}].active`, !service.active);
                                      await Promise.resolve();
                                      submitForm();
                                  }}/>
                        </div>
                        <div className="col-sm-2 px-sm-0">
                            <Field component="select"
                                   name={`services[${index}].side`}
                                   className="form-control form-select"
                                   value={service.side || '-'}>
                                <option value="">-</option>
                                <option value="B">B</option>
                                <option value="R">R</option>
                                <option value="L">L</option>
                            </Field>
                        </div>
                        <div className="col-sm-3 px-sm-0">
                            <DatePicker
                                name={`services[${index}].date`}
                                value={service.date ? Date.parse(service.date) : null}
                                onChange={(v, vi) => {
                                    // set hours to 2 to avoid problems with time zone and summer time conversions
                                    vi.setHours(2);
                                    setFieldValue(v, vi)
                                }}
                            />
                        </div>
                        <div className="col-sm-2 px-0" title={i18n.t('tarpo_number')}>
                            <Field type="float"
                                   name={`services[${index}].number`}
                                   id={`services[${index}].number`}
                                   className={"form-control"}
                                   value={service && service.number || ''}
                            />
                            <span
                                className="fa fa-remove removetag"
                                onClick={async () => {
                                    arrayHelpers.remove(index);
                                    await Promise.resolve();
                                    submitForm();
                                }}
                                title={i18n.t('delete_code')}/>
                        </div>
                    </div>
                </div>
                    <div className="col-lg-5 code_description">
                        {user.admin && <div title={service.date} className="badge bg-light me-1">{service.day}</div>}
                        {service.url === '' ?
                            <span>{service.text}</span> : <a href={service.url} target={service.code}>{service.text}</a>
                        }
                    </div>
                </div>
            </div>
            )
            };

            export default Service

import {http} from './api';
import {createCancelTokenHandler} from "./axiosUtils";

export const PatientCaseService = {
  update: (data, id) => http.patch(`patient_cases/${id}`, data, {
    cancelToken: cancelTokenHandler['update'].handleRequestCancellation().token
  }),
};

const cancelTokenHandler = createCancelTokenHandler(PatientCaseService)

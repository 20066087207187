import * as React from 'react';
import { i18n } from "../../config/i18n";
import {IInteractiveSummaryProps} from "./Summary"
import {SummaryService} from "../../services/SummaryService";
import Select from 'react-select'
import {drawChart} from "../../utils/chartsUtils";
import _ from "lodash";
import Spinner from '../Spinner';
import {useContext, useEffect, useState} from "react";
import {LocaleContext} from "../../contexts/LocaleContext";
import {getUrlParam} from "../../utils/UrlUtils";
import { useVariables } from '../../contexts/VariableContext';
import { ExistingFilterContext } from '../../contexts/ExistingFilterContext';
import MCLEditor from '../medcodelogic/MCLEditor';
import MCLEditorErrorBoundary from '../medcodelogic/MCLEditorErrorBoundary';

interface Props {
    isAdmin: boolean,
    interactiveProps?: IInteractiveSummaryProps
}

/**
 * interactive chart with two dimensions:
 * first dimension: numerical or categorical variable with binSize for numerical and maxValue for both
 * second dimension: numerical with aggregator or just num_cases (in the case of a histogram)
 */
const InteractiveChart: React.FunctionComponent<Props> = (props) => {
    const encodedParams = getUrlParam('interactive', location.search);
    const [initXVariable, initYVariable, initYAggregator, initMinValue, initMaxValue, initLogicSrcVariable
        , initLogicSrcYVariable, initIsMclYVariable, initIsMclVariable, initYOrder,
        initBinSize] = encodedParams ? encodedParams.split('~') : [null, null, null, null, null, '', '', null, null, null]

    const [resultA, setResultA] = useState<{[Identifier: string]: number}>(undefined)
    const [resultB, setResultB] = useState<{[Identifier: string]: number}>(undefined)
    const [numCasesA, setNumCasesA] = useState<number>(undefined)
    const [numCasesB, setNumCasesB] = useState<number>(undefined)
    const [minValue, setMinValue] = useState<number>(parseInt(initMinValue) || 0)
    const [maxValue, setMaxValue] = useState<number>(parseInt(initMaxValue) || 0)
    const [xVariable, setXVariable] = useState<string>(initXVariable || 'total_costs')
    const [yVariable, setYVariable] = useState<string>(initYVariable || 'num_cases')
    const [yAggregator, setYAggregator] = useState<string>(initYAggregator|| 'avg')
    const [yOrder, setYOrder] = useState<string>(initYOrder || 'desc')
    const [binSize, setBinSize] = useState<number>(parseInt(initBinSize) || 1)
    const [reload, setReload] = useState<boolean>(true)
    const doReload = () => setReload(true)
    const [isMclVariable, setIsMclVariable] = useState<boolean>(initIsMclVariable === "true" ? true : false)
    const [isMclYVariable, setIsMclYVariable] = useState<boolean>(initIsMclYVariable === "true" ? true : false)
    const [logicSrcVariable, setLogicSrcVariable] = useState<string>(decodeURIComponent(initLogicSrcVariable.replace(/\+/g, ' ')) || '')
    const [logicSrcYVariable, setLogicSrcYVariable] = useState<string>(decodeURIComponent(initLogicSrcYVariable.replace(/\+/g, ' ')) || '')
    const [errorMessage, setErrorMessage] = useState<string>(null)
    const [xVariableType, setXVariableType] = useState<string>(undefined)

    const {variables: variablesFromContext, variableOptions} = useVariables();

    const [numCasesByVarA, setNumCasesByVarA] = useState<{[Identifier: string]: number}>(undefined)
    const [numCasesByVarB, setNumCasesByVarB] = useState<{[Identifier: string]: number}>(undefined)

    const locale = useContext<string>(LocaleContext)
    const existingFilter = useContext(ExistingFilterContext)
    const variables = variablesFromContext

    useEffect(() => {
        if(reload && variables) {
            loadChartData()
        }
    }, [reload, variables]);

    React.useEffect(() => {
        setErrorMessage(null)
    }, [logicSrcVariable, logicSrcYVariable]);

    // do nothing before variables are loaded
    if (variables === undefined || variableOptions === undefined)
        return <></>

    const isNumerical = (isMclVariable ? xVariableType : _.get(variables[xVariable], 'variable_type', '')) === 'number'

    /** load chart data from backend. */
    function loadChartData() {
        const isHist = yVariable == 'num_cases'
        const finalXVariable = isMclVariable ? logicSrcVariable : xVariable
        const finalYVariable = isMclYVariable ? logicSrcYVariable : yVariable

        SummaryService.chartData(isHist ? 'hist' : 'bar',
            finalXVariable, binSize, isHist ? undefined : finalYVariable, isHist ? undefined : yAggregator,
            isMclVariable, isMclYVariable, props.interactiveProps)
            .then(response => (response.status != 200 ?
                { error_message: response.data ? ('Server error: ' + response.data.substring(0, 50)) : 'empty' } : response.data))
            .then((data) => {
                if(data['error_message'] == 'empty') {
                    return;// probably just cancelled, do nothing
                }
                try {
                    // add descriptions to translations
                    const transformedDescriptions = {};
                    for (const [key, value] of Object.entries(data['descriptions'])) {
                        const transformedKey = key.replace(/\./g, '');
                        transformedDescriptions[transformedKey] = value;
                    }
                    i18n.translations[i18n.locale] = {...i18n.translations[i18n.locale], ...transformedDescriptions}
                    const valueA = data[finalXVariable]['A']
                    const valueB = data[finalXVariable]['B']

                    // set min- and maxValue if not already set by user
                    let minV = Math.min(...Object.keys(valueA).map(e => parseFloat(e)))
                    let maxV = Math.max(...Object.keys(valueA).map(e => parseFloat(e))) + 1.0
                    if(valueB) {
                        minV = Math.min(minV, ...Object.keys(valueB).map(e => parseFloat(e)))
                        maxV = Math.max(maxV, ...Object.keys(valueB).map(e => parseFloat(e) + 1.0))
                    }
                    // Math.min of empty object returns infinity, which we want to catch here.
                    if(minV == Infinity) { minV = 0; }
                    const minVFloor = Number.isNaN(minV) ? 0 : Math.floor(minV)
                    // If xVariable is case_number we want to catch huge maxValues and set them to 15, since otherwise
                    // interactive chart gets huge.
                    const isNumericalCurrent = (isMclVariable ? data[finalXVariable]['x_variable_type'] : _.get(variables[xVariable], 'variable_type', '')) === 'number'
                    const maxVFloor = (maxV == 0 || Number.isNaN(maxV) || !isNumericalCurrent) ? 15 : (maxV > 5 ? Math.floor(maxV) : maxV)
                    setResultA(valueA)
                    setResultB(valueB)
                    setNumCasesA(data[finalXVariable]['num_cases_A'])
                    setNumCasesB(data[finalXVariable]['num_cases_B'])
                    setMinValue(minValue == 0 ? minVFloor : minValue)
                    setMaxValue(maxValue == 0 ? maxVFloor : maxValue)
                    setXVariableType(data[finalXVariable]['x_variable_type'])
                    if (isHist) {
                        setNumCasesByVarA(valueA)
                        setNumCasesByVarB(valueB)
                        setErrorMessage(null)
                    } else {
                        SummaryService.chartData('hist', finalXVariable, binSize, '',
                            undefined, isMclVariable, false, props.interactiveProps)
                            .then(response => (response.status != 200 ?
                                { error_message: response.data ? ('Server error: ' + response.data.substring(0, 50)) : 'empty' } : response.data))
                            .then((data) => {
                                if(data['error_message'] == 'empty') {
                                    return;// probably just cancelled, do nothing
                                }
                                try {
                                    setNumCasesByVarA(data[finalXVariable]['A'])
                                    setNumCasesByVarB(data[finalXVariable]['B'])
                                    setErrorMessage(null)
                                } catch(e) {
                                    setErrorMessage(data['error_message'])
                                }
                            });
                    }
                } catch(e) {
                    setResultA(undefined)
                    setResultB(undefined)
                    setMinValue(0)
                    setMaxValue(0)
                    setErrorMessage(data['error_message'])
                }
                setReload(false)
            })
    }

    function updateURL() {
        // @ts-ignore
        const url = new window.URL(document.location);
        const encodedParams = [xVariable, yVariable, yAggregator, minValue, maxValue, logicSrcVariable, logicSrcYVariable,
            isMclYVariable, isMclVariable, yOrder, binSize].join("~")
        url.searchParams.set('interactive', encodedParams);
        history.replaceState(null, null, url.toString());
    }

    /* set xVariable. */
    function updateVariable(value) {
        setResultA(undefined)
        setResultB(undefined)
        setXVariable(value)
        setIsMclVariable(value === 'dynamic')
        setMinValue(0)
        setMaxValue(0)
        setBinSize(1)
        doReload()
        return false
    }

    function updateMclVariable(value){
        setResultA(undefined)
        setResultB(undefined)
        setLogicSrcVariable(value)
    }

    function updateYMclVariable(value){
        setResultA(undefined)
        setResultB(undefined)
        setLogicSrcYVariable(value)
    }

    function updateYVariable(value) {
        setResultA(undefined)
        setResultB(undefined)
        setIsMclYVariable(value === 'dynamic')
        setYVariable(value)
        doReload()
        return false
    }

    function updateBinSize(value) {
        const positiveValue = value < 0 ? 1 : value
        setResultA(undefined)
        setResultB(undefined)
        setBinSize(positiveValue)
        if(positiveValue > 0)
            setReload(true)

        return false
    }

    function updateMaxValue(value) {
        setMaxValue(value)
        return false
    }

    function updateMinValue(value) {
        setMinValue(value)
        return false
    }

    function toggleYOrder() {
        setYOrder(yOrder == 'desc' ? 'asc' : 'desc')
        return false
    }

    function updateYAggregator(value) {
        setResultA(undefined)
        setResultB(undefined)
        setYAggregator(value)
        setReload(true)
        return false
    }

    /* reduce hash to entries with minValue <= key < maxValue. used for numerical xVariables. */
    function reduceResultToMinMaxKey(result){
        return Object.keys(result)
            .filter(key => parseFloat(key) < maxValue)
            .filter(key => minValue <= parseFloat(key))
            .reduce((obj, key) => {
                obj[key] = result[key];
                return obj;
            }, {});
    }

    /* calculate keys that are among N best / highest in both groups. used for categorical xVariables. */
    function prepareReduceResultNBest() {
        const keysA = Object.keys(resultA)
        let keysTotal = resultB != undefined ? keysA.concat(Object.keys(resultB)) : keysA
        // @ts-ignore
        // Remove duplicates to ensure maxValue reduces correctly for comparison summaries. Important for categorical
        // variables like 'drg' where we want to show results for n best unique values.
        keysTotal = [...new Set(keysTotal)]
        // convert to array
        const resultsTotal = keysTotal.map(key => {
            return {
                key: key,
                value: (resultA[key] ? resultA[key] : 0.0) + (resultB && resultB[key] ? resultB[key] : 0),
            };
        });
        resultsTotal.sort((a, b) => (a.value - b.value))
        if(yOrder === 'desc')
            resultsTotal.reverse()
        if(maxValue === undefined)
            return Object.keys(resultsTotal)

        return resultsTotal.slice(0, maxValue).map(e => e.key)
    }

    /* used for categorical xVariables. */
    function filterHashByIDList(result, keyList){
        return keyList.reduce((cur, key) => { return Object.assign(cur, { [key]: result[key] })}, {})
    }

    const finalXVariable = isMclVariable ? logicSrcVariable : xVariable
    const finalYVariable = isMclYVariable ? logicSrcYVariable : yVariable

    i18n.locale = locale;
    const isHist = yVariable == 'num_cases'
    // variable options
    const options = variableOptions.map(group => ({
        label: group.label,
        options: group.options.map(option => ({
                value: option.value,
                label: option.value + ': ' + option.label,
                dimensionless: _.get(variables[option.value], 'dimensionless', false),
                variableType: _.get(variables[option.value], 'variable_type', '')
            }
        ))
    }))
    // filter variable options
    const numericalOptions = options.map(group => Object.assign({}, group, {
        options:
            group.options.filter(o => o.variableType === 'number' && !o.dimensionless)
    }))
    const numericalAndCategoricalOptions = options.map(group => Object.assign({}, group, {
        options:
            group.options.filter(o => ['number', 'string', 'code'].includes(o.variableType))
    }))

    numericalAndCategoricalOptions.unshift({
        label: 'dynamic',
        options: [{value: 'dynamic', label: i18n.t('mcl_variable'), variableType: 'number', dimensionless: false}]
    })
    numericalOptions.unshift({
        label: 'dynamic',
        options: [{value: 'dynamic', label: i18n.t('mcl_variable'), variableType: 'number', dimensionless: false}]
    })

    const defaultLabel = xVariable === 'dynamic' ?  i18n.t('mcl_variable') : (variables[xVariable] ? variables[xVariable]['name_' + locale] : '')
    const defaultLabelY = yVariable === 'dynamic' ?  i18n.t('mcl_variable') : (variables[yVariable] ? variables[yVariable]['name_' + locale] : i18n.t('num_cases'))
    const elementPrefix = finalXVariable.replace(/\W/g,"")

    updateURL()
    if (resultA != undefined) {
        const resA = {numberOfPatientcases: numCasesA}
        const totalResA = {numberOfPatientcases: numCasesA}
        const maxKeys = isNumerical ? undefined : prepareReduceResultNBest()
        resA[finalXVariable] = isNumerical ? reduceResultToMinMaxKey(resultA) :
            filterHashByIDList(resultA, maxKeys)
        totalResA[finalXVariable] = resultA
        const resB = resultB ? {numberOfPatientcases: numCasesB} : undefined
        const totalResB = resultB ? {numberOfPatientcases: numCasesB} : undefined
        if (resB) {
            resB[finalXVariable] = isNumerical ? reduceResultToMinMaxKey(resultB) :
                filterHashByIDList(resultB, maxKeys)
            totalResB[finalXVariable] = resultB
        }

        const minYValue = Math.min(...([...Object.values(resA[finalXVariable]),
            ...Object.values(resB ? resB[finalXVariable] : {})]).map((e: string) => parseFloat(e)))
        const minXValue = isNumerical ? minValue : 0
        const columnAxis = [maxValue, finalXVariable, minXValue ]
        const dataAxis = [0, resultB && isHist ? 'percentage' : finalYVariable, minYValue < 0 ? minYValue : 0]
        // Add number of cases by variable to resA and resB if present.
        if (numCasesByVarA) {
            resA["numberOfPatientcasesByVar"] = numCasesByVarA
        }
        if (numCasesByVarB) {
            resB["numberOfPatientcasesByVar"] = numCasesByVarB
        }
        // the chart axes are switched for categorical variables.
        const chartOptions = {
            name: finalXVariable,
            valueVariableName: finalYVariable,
            types: [isNumerical ? 'number' : 'string', 'number'],
            hAxis: isNumerical ? columnAxis : dataAxis,
            vAxis: isNumerical ? dataAxis : columnAxis,
            height: isNumerical ? undefined : 'auto',
            elementNameSuffix: '_interactive_chart_div', // avoid conflicts with '_chart_div'
            sortOrder: yOrder, // only relevant for categorical / bar chart
            hasMcl: isMclVariable
        }

        // @ts-ignore
        google.charts.setOnLoadCallback(function () {
            drawChart(resA, resB, chartOptions, isNumerical ? 'column' : 'bar', binSize, props.isAdmin, totalResA, totalResB, existingFilter);
        });
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <strong>
                    {i18n.t('interactive_chart')}
                </strong>
            </div>
            <div className="card-body p-0">
                <div className="row mt-2 me-1 ms-1">
                    <div className={`col-${isNumerical ? 6 : 8}`}>
                        <label>{i18n.t('x_axis')}:</label>
                        <Select
                            options={numericalAndCategoricalOptions}
                            defaultValue={{value: xVariable, label: defaultLabel}}
                            onChange={(e) => {
                                updateVariable(e.value)
                            }}
                            isSearchable={true}
                            menuPortalTarget={document.querySelector('body')}
                        />
                        {isMclVariable &&
                            <div className="col-lg-12 form-control mt-2">
                                <MCLEditorErrorBoundary editor={
                                    <MCLEditor logicSrc={logicSrcVariable || ''}
                                        setLogicSrc={updateMclVariable}
                                        onEnter={doReload}
                                        miniVersion={true} />
                                } />
                            </div>
                        }
                    </div>
                    {isNumerical &&
                        <>
                            <div className="col-2">
                                <label>{i18n.t('bin_size')}</label>
                                <input onChange={(e) => {
                                    updateBinSize(e.target.value)
                                }}
                                       value={binSize}
                                       className="form-control"
                                       type="number"
                                />
                            </div>
                            <div className="col-2">
                                <label>{i18n.t('min')}</label>
                                <input onChange={(e) => {
                                    updateMinValue(e.target.value)
                                }}
                                       value={minValue}
                                       className="form-control"
                                       type="number"
                                />
                            </div>
                        </>
                    }
                    <div className="col-2">
                        <label>{i18n.t('max')}</label>
                        <input onChange={(e) => {
                            updateMaxValue(e.target.value)
                        }}
                               value={maxValue}
                               className="form-control"
                               type="number"
                        />
                    </div>
                    <div className="row mt-1">
                        <div className="col-8">
                            <label>{i18n.t('y_axis')}:</label>
                            <Select
                                options={[...[{label: i18n.t('num_cases'), value: 'num_cases'}],
                                    ...numericalOptions]}
                                defaultValue={{label: defaultLabelY, value: yVariable}}
                                onChange={(e) => {
                                    updateYVariable(e.value)
                                }}
                                isSearchable={true}
                                menuPortalTarget={document.querySelector('body')}
                            />
                            {isMclYVariable &&
                                <div className="col-lg-12 form-control mt-2">
                                    <MCLEditorErrorBoundary editor={
                                        <MCLEditor logicSrc={logicSrcYVariable || ''}
                                            setLogicSrc={updateYMclVariable}
                                            onEnter={doReload}
                                            miniVersion={true} />
                                    } />
                                </div>
                            }
                        </div>
                        {!isHist &&
                            <div className="col-2">
                                <label>{i18n.t('aggregator')}</label>
                                <Select
                                    name="aggregator"
                                    id="aggregator_selection"
                                    defaultValue={{value: yAggregator, label: yAggregator}}
                                    isSearchable={false}
                                    onChange={(e) => updateYAggregator(e.value)}
                                    options={['avg', 'sum', 'max', 'min', 'count', 'stddev', 'variance', 'median', 'homogeneity']
                                        .map(e => ({label: e, value: e}))}
                                    menuPortalTarget={document.querySelector('body')}
                                    components={{DropdownIndicator: () => <></>}}
                                />
                            </div>}
                        {!isNumerical &&
                            <div className="col-2">
                                <label>{i18n.t('sort_order')}</label>
                                <button className="btn btn-outline-secondary"
                                        onClick={() => toggleYOrder()}>
                                    <i className={"fa fa-sort-" + yOrder}/>
                                </button>
                            </div>
                        }
                    </div>
                </div>
                {errorMessage ?
                    <div className="alert alert-warning m-2">{errorMessage}</div>
                    : <>{resultA ? <>
                        <div id={elementPrefix + "_interactive_chart_div"}></div>
                        <div className="row">
                            <div className="col-1 ms-auto">
                                <span className="me-1" id={elementPrefix + "_png_download"} />
                                <span id={elementPrefix + "_csv_download"} />
                            </div>
                        </div>
                    </> : <>{reload ? <Spinner /> : 
                        <div className='col-lg-12 m-2'>&gt;_</div>}</>}</>
                }
            </div>
        </div>
    );
}

export default InteractiveChart

import * as React from 'react';
import Autocomplete from './Autocomplete';
import {ServicesService} from '../services/ServicesService.js';
import {Field} from "formik";


interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    submitForm: Function;
}

const ServiceAutocomplete: React.FunctionComponent<Props> = props => {
    const {name, value} = props

    /* TODO: set URL prefix as soon as available in medcodesearch and then remove the condition urlPrefix == undefined. */
    const urlPrefix = undefined
    return (
        <>
        { urlPrefix == undefined ?
            <Field type="float"
                   name={name}
                   id={name}
                   className={"form-control"}
                   value={value}
            /> : <Autocomplete {...props} service={ServicesService.create(urlPrefix)}/>}
        </>
    );
};

export default ServiceAutocomplete;

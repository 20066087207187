import * as React from 'react';
import { i18n } from "../config/i18n";
import {useContext} from "react";
import {IPatientCase, IUser} from "../interfaces";
import {UserContext} from "../contexts/UserContext";
import {CancelToken} from "apisauce";
import {PatientCaseService} from "../services/PatientCaseService";
import {toast} from "react-toastify";

interface Props {
    isSubmitting: boolean;
    reset: Function;
    patientCase: IPatientCase;
    id: number;
    hideUpdate?: boolean;
}

const FormButtons: React.FunctionComponent<Props> = ({ isSubmitting, reset, patientCase, id, hideUpdate }) => {
    const user = useContext<IUser>(UserContext)

    function save() {
        //const urlEncodedPc = getUrlParam('pc', location.search);
        PatientCaseService.update({patient_case: patientCase}, id)
            .then(response => {
                if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                    toast(`Something went wrong: ${response["error"]}`, {type: 'error'});
                }
                return response;
            })
            .then(response => response.data)
            .then((data) => {
                if (data && data.patient_case) {
                    toast(i18n.t('successfull_save'), {type: 'success'});
                }
            });
    }

    return (
        <div className="text-start">
            <button
                onClick={() => reset()}
                type="button"
                disabled={isSubmitting}
                className="btn btn-secondary me-2"
            >
                {i18n.t("reset_case")}
            </button>
            {!hideUpdate &&
                <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
            >
                {i18n.t('update')}
            </button>}
            { user.admin && id &&
                <button
                    onClick={() => save()}
                    type="button"
                    disabled={isSubmitting}
                    className="btn btn-primary ms-2"
                >
                    {i18n.t('save')}
                </button>
            }
        </div>
    )
};

export default FormButtons

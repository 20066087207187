import {IPatientCase} from "../interfaces";

export const PATIENT_CASE_DEFAULT_STATE: { patient_case: IPatientCase, rules: any[]} = {
  patient_case: {
    sex: "W",
    age_years: 40,
    age_days: 0,
    age_selection: "1",
    adm_date: null,
    procedures: [{code: "", side: "", date: "", active: true, valid: true, used: false, text: ''}],
    main_diagnosis: {code: "", active: true, valid: true, used: false, text: ''},
    secondary_diagnoses: [],
    drugs: [],
    tarpos: [{code: "", number: 1, active: true, amount: 0.0, valid: true, text: '', tariff: '001'}],
    services: [{code: "", active: true, valid: true, used: false, text: '', number: 1, side: ''}],
    groups: [],
    tpw: 0.9
  },
  rules: [],
};

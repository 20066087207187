import * as React from 'react';
import {FieldArray} from 'formik';
import Tarpo from './Tarpo';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ChainsService} from "../../services/ChainsService";
import {toast} from "react-toastify";
import {ITarpo} from "../../interfaces";
import {i18n} from "../../config/i18n";

interface Props {
    tarpos: ITarpo[];
    setFieldValue: any;
    submitForm: any;
    locale: string;
    title: string;
    bgColor?: string;
    displayTariffs?: string[];
    id: string;
}

const Tarpos: React.FunctionComponent<Props> = props => {
    const {tarpos, setFieldValue, submitForm, bgColor, displayTariffs, title, id} = props;

    const onDragEnd = (result, move) => {
        const { source, destination } = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        // move code to destination
        move(source.index, destination.index);
        submitForm();
    };

    const getTarpoChain = (tarpo, tarpoPosition, processChain) => {
        const {locale} = props;
        // points do not work in IDs. This will be mitigated by calling code_point in the backend
        const paramCode = tarpo.code.replace('.', '')
        ChainsService.create({}, paramCode, tarpo.tariff, locale)
            .then(response => {
                if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                    toast('Something went wrong', {type: 'error'});
                }
                return response;
            })
            .then(response => response.data)
            .then((data) => {processChain(data)});
    }

    const expandTarpo = (tarpo, tarpoPosition) => {
        getTarpoChain(tarpo, tarpoPosition, async  (data) => {
            const chain = data['chain'];
            for(let i=0; i< chain.length; i++){
                const code = chain[i]
                const index = tarpos.length - 1;
                setFieldValue(`tarpos[${index + i}].code`, code.code);
                setFieldValue(`tarpos[${index + i}].tariff`, code.tariff);
                setFieldValue(`tarpos[${index + i}].number`, code.number);
                setFieldValue(`tarpos[${index + i}].amount`, code.amount);
            }
            // delete original code
            // first avoid infinite loop in Tarpo.OverlayTrigger by setting has_chain to false and hence not rerender OverlayTrigger.
            setFieldValue(`tarpos[${tarpoPosition}].has_chain`, false);
            setFieldValue(`tarpos[${tarpoPosition}].code`, '');
            await Promise.resolve();
            submitForm();
        })
    };

    return (
        <div className="codes_box" id={"tarpos_box_" + id} style={{backgroundColor: bgColor}}>
            <div className="row">
                <div className="col-sm-12">
                    <h4>{title}</h4>
                </div>
            </div>
            <div>
                <div className="code_input_div row">
                    <div className="col-lg-7 pe-0 text-secondary" style={{fontSize: 8}}>
                        <div className="input-group">
                            <div className="col-sm-4 px-0 me-3">
                                Code
                            </div>
                            <div className="col-sm-2 px-0">
                                {i18n.t('tarpo_number')}
                            </div>
                            <div className="col-sm-2 px-0">
                                {i18n.t('tariff_code')}
                            </div>
                            <div className="col-sm-3 px-0">
                                {i18n.t('amount')}
                            </div>
                        </div>
                    </div>
                </div>
                <FieldArray
                    name="tarpos"
                    render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => {
                            onDragEnd(result, arrayHelpers.move)
                        }}>
                            <Droppable droppableId="tarpo-dropzone">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {tarpos && tarpos.map((tarpo, index) => (
                                            (displayTariffs == undefined || displayTariffs.includes(tarpo.tariff)) &&
                                            <Draggable key={index} draggableId={'tarpo' + index} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Tarpo
                                                            index={index}
                                                            tarpo={tarpo}
                                                            setFieldValue={setFieldValue}
                                                            submitForm={submitForm}
                                                            key={index}
                                                            arrayHelpers={arrayHelpers}
                                                            expandTarpo={expandTarpo}
                                                            getTarpoChain={getTarpoChain}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>

                                        ))}
                                        <div className="row pb-2">
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.push(
                                                        {
                                                            code: "",
                                                            number: 1,
                                                            active: true,
                                                            tariff: displayTariffs == undefined ? '' : displayTariffs[0]
                                                        })}
                                                    className="pull-right btn btn-secondary btn-sm pe-2 ps-2"
                                                >
                                                    <i className="fa fa-plus"/>
                                                </button>
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                />
            </div>
        </div>
    );
};

export default Tarpos

import {create} from "apisauce";

export const http = create({
    // eslint-disable-next-line no-undef
    baseURL: (medcodesearchSearchPath),
    headers: {Accept: 'application/json'},
});

export const ServicesService = {
    create: (urlPrefix) => ({
        get: (term, version) => http.get(urlPrefix.replace('$$VERSION$$', version) + '/search',
            {
                search: term,
                terminal_only: 1,
                max_results: 10,
                highlight: 1
            })
    })
};
